import { PageProps, graphql } from "gatsby";
import React from "react";

import FaqsCategories from "@components/faq/faqsCategories";
import { BlocksContent } from "@components/global";
import LearnCategoryPreview from "@components/global/learnCategoryPreview";
import { Query } from "@graphql-types";
import SEO from "@shared/seo";
import { Container } from "@util/standard";
import ContactForm from "@components/contact/contactForm";
import Layout from "@shared/layout";

interface Props extends PageProps {
  data: Query;
}

function Contact({ data }: Props) {
  if (data == null || data.sanityContact == null) {
    return null;
  }

  const { contactHero, seo, categoryPreview, formSubjectOptions, formCountryOptions, formSuccessMessage } =
    data.sanityContact;

  return (
    <div>
      <SEO seoData={seo} />
      <Layout>
        <Container
          width="65%"
          tabletWidth="80%"
          margin="220px auto 140px auto"
          tabletMargin="220px auto 40px auto"
          flexDirection="column"
        >
          {contactHero && <BlocksContent blocks={contactHero._rawColumnContent} />}
          <ContactForm
            successMsg={formSuccessMessage}
            countryOptions={formCountryOptions}
            subjectOptions={formSubjectOptions}
          />
        </Container>
        {categoryPreview && <LearnCategoryPreview data={categoryPreview} />}
      </Layout>
    </div>
  );
}

export default Contact;

export const query = graphql`
  {
    sanityContact {
      seo {
        ...sanitySeo
      }
      contactHero {
        ...sanityColumn
      }
      categoryPreview {
        ...sanityLearnCategoryPreview
      }
      formSubjectOptions
      formCountryOptions
      formSuccessMessage {
        ...sanityColumn
      }
    }
  }
`;
