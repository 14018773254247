import React, { ReactNode, useEffect, useState } from "react";
import styled from "styled-components";
import { ReCaptcha } from "react-recaptcha-v3";
import { toast } from "react-toastify";
import { Dropdown } from "react-dropdown-now";

import { BlocksContent, Button, Checkbox } from "@components/global";
import { colorsRGB, fontFamilies, icons } from "@util/constants";
import { Container, P, H3 } from "@util/standard";
import { useCheckScreenWidth } from "@util/hooks";
import { activeCampaignAddContact, processEmail } from "@util/helper";
import { SanityColumn } from "@graphql-types";

interface Props {
  countryOptions: string[];
  subjectOptions: string[];
  successMsg?: SanityColumn;
}

interface DropDownProps {
  text: string;
}

interface OptionProps {
  value: string;
  label: string;
  view: ReactNode;
}

const Input = styled.input`
  background: transparent;
  border: none;
  border-bottom: 1px solid ${colorsRGB.black(0.4)};

  font-size: 15px;
  padding-bottom: 10px;

  text-align: start;
  :focus-visible {
    outline: none;
  }
`;

const StyledDropdown = styled(Dropdown)<{ margin?: string }>`
  width: 100%;
  color: black;
  ${({ margin }) => margin && `margin: ${margin};`};
  .rdn-control {
    width: 100%;
    border: 1px solid black;
    padding-left: 20px;
  }

  .is-selected {
    width: 100%;
    background: transparent;
  }

  .rdn-drop {
    overflow-y: visible;
    max-height: none;
    border: 1px solid black;
    z-index: 4;
    width: 100%;
  }

  .rdn-control-arrow-icon {
    border-style: none;
    background: url(${icons.arrow});
    width: 14px;
    height: 9px;
    margin-right: 5px;
  }
  .rdn-drop-menu-option {
    color: black;
    padding: 0 0 0 20px;
    font-weight: 600 !important;

    &:hover {
      background: transparent;
    }
  }
`;

const Textarea = styled.textarea`
  background: transparent;
  border: none;
  border-bottom: 1px solid ${colorsRGB.black(0.4)};

  min-height: 120px;
  font-size: 15px;
  padding-bottom: 10px;

  text-align: start;
  :focus-visible {
    outline: none;
  }
  font-family: ${fontFamilies.proximaNova};

  ::placeholder {
    font-family: ${fontFamilies.proximaNova};
  }
`;

function ContactForm({ subjectOptions, countryOptions, successMsg }: Props) {
  const [countries, setCountries] = useState<OptionProps[]>([]);
  const [subjects, setSubjects] = useState<OptionProps[]>([]);
  const [formDisable, setFormDisable] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
    country: "",
    subject: "",
    newsLetter: false,
  });

  const { isLaptopWidth } = useCheckScreenWidth();
  const DropDownButton = ({ text }: DropDownProps) => {
    return (
      <Container>
        <P width="100%" margin="7px 0px">
          {text}
        </P>
      </Container>
    );
  };

  const setOption = (optionSet: string[]) => {
    const options: OptionProps[] = [];
    optionSet.forEach(option => {
      options.push({ value: option, label: option, view: <DropDownButton text={option} /> });
    });
    return options;
  };

  const verifyCallback = (recaptchaToken: string) => {
    if (recaptchaToken) setFormDisable(false);
  };

  useEffect(() => {
    if (subjectOptions) {
      setSubjects(setOption(subjectOptions));
    }
    if (countryOptions) {
      setCountries(setOption(countryOptions));
    }
  }, []);

  const handleContactFormSubmit = e => {
    e.preventDefault();

    if (formData.email === "" || !formData.email.includes("@")) {
      toast.error("Please enter a correct email");
      return;
    }

    if (formData.firstName === "" || formData.lastName === "") {
      toast.error("Please enter your first and last name");
      return;
    }

    const formattedEmail = {
      from: "Langbein.com<info@langbein.com>",
      to: process.env.GATSBY_CONTACT_RECIPIENT_EMAIL,
      subject: `Langbein.com contact enquiry: ${formData.firstName} ${formData.lastName}`,
      html: `<div>
      <p>Name: ${formData.firstName} ${formData.lastName}</p>
      <p>Email: ${formData.email}</p>
      <p>Phone: ${formData.phone}</p>
      <p>Country: ${formData.country}</p>
      <p>Subject: ${formData.subject}</p>
      <p>Message: ${formData.message}</p>
      <p>subscribed to newsletter: ${formData.newsLetter}</p>
      </div>
        `,
    };
    processEmail(formattedEmail)
      .then(result => {
        setFormSubmitted(true);
      })
      .catch(error => {
        toast.error("Something went wrong");
      });
  };

  return (
    <>
      {!formSubmitted && !formDisable && (
        <form onSubmit={e => handleContactFormSubmit(e)}>
          <Container flexDirection="column" width="100%" margin="70px 0 0 0">
            <Container margin="0 0 25px 0" flexDirection={isLaptopWidth ? "row" : "column"}>
              <Container flexDirection="column" width="100%" margin="0 15px 0 0" tabletMargin="0 0 30px 0">
                <Input
                  aria-label="First Name"
                  placeholder="First Name"
                  onChange={e => setFormData({ ...formData, firstName: e.target.value })}
                />
              </Container>
              <Container flexDirection="column" width="100%">
                <Input
                  aria-label="Last Name"
                  placeholder="Last Name"
                  onChange={e => setFormData({ ...formData, lastName: e.target.value })}
                />
              </Container>
            </Container>
            <Container margin="0 0 25px 0" flexDirection={isLaptopWidth ? "row" : "column"}>
              <Container flexDirection="column" width="100%" margin="0 15px 0 0" tabletMargin="0 0 30px 0">
                <Input
                  aria-label="Email address"
                  placeholder="Email address"
                  type="email"
                  onChange={e => setFormData({ ...formData, email: e.target.value })}
                />
              </Container>
              <Container flexDirection="column" width="100%">
                <Input
                  placeholder="Phone (optional)"
                  aria-label="Phone Number (optional)"
                  onChange={e => setFormData({ ...formData, phone: e.target.value })}
                />
              </Container>
            </Container>
          </Container>
          <Container margin="0 20px 25px 0" width="100%" flexDirection={isLaptopWidth ? "row" : "column"}>
            <Container width="100%" margin="0 15px 0 0" tabletMargin="0 0 25px 0">
              <StyledDropdown
                aria-label="Country"
                placeholder="Country"
                options={countries}
                value=""
                onChange={option => setFormData({ ...formData, country: option.value as string })}
              />
            </Container>
            <Container width="100%">
              <StyledDropdown
                aria-label="Area of enquiry"
                placeholder="Area of enquiry"
                options={subjects}
                value=""
                onChange={option => setFormData({ ...formData, subject: option.value as string })}
              />
            </Container>
          </Container>
          <Container flexDirection="column" width="100%">
            <Textarea
              aria-label="Message"
              placeholder="Enter message"
              onChange={e => setFormData({ ...formData, message: e.target.value })}
            />
          </Container>
          <Container
            justifyContent="space-between"
            margin="30px 0 0 0"
            flexDirection={isLaptopWidth ? "row" : "column"}
          >
            <Container
              onClick={() => setFormData({ ...formData, newsLetter: !formData.newsLetter })}
              tabletMargin="0 0 40px 0"
            >
              <Checkbox isDark checked={formData.newsLetter} />{" "}
              <P margin="auto 0 auto 15px">Sign up for our newsletters</P>
            </Container>
            <Button text="Submit" type="submit" theme="block" />
          </Container>
        </form>
      )}
      {formSubmitted && <>{successMsg && <BlocksContent blocks={successMsg._rawColumnContent} />}</>}
      <ReCaptcha sitekey={process.env.RECAPTCHA_KEY} verifyCallback={verifyCallback} />
    </>
  );
}

export default ContactForm;
